* {
    box-sizing: border-box;
}

body {
    min-height: 200vh;
    background: #551695;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3d0059, #551695);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3d0059, #551695); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

p, h1, h2, h3, h4, h5, h6, span, strong, button, a {
    font-family: 'Roboto Condensed', sans-serif;    
}

p { font-weight: regular };

